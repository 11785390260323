import React from 'react'
//components
import { Pagination, UserRow } from 'components'
//styles
import styles from './styles.module.scss'

const ManageTable = ({
  headers,
  body,
  onClickDelete,
  onClickBan,
  setSelectedItem,
  incrementPage,
  decrementPage,
  page,
  lastPage,
}) => {
  return (
    <div className={styles.manageTable}>
      <div className={styles.manageTable__inner}>
        <table>
          <thead>
            <tr>
              {headers.map((title) => (
                <td className={styles.manageTable__td} key={title}>
                  {title}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((item) => (
              <UserRow
                key={item._id}
                item={item}
                onClickDelete={onClickDelete}
                onClickBan={onClickBan}
                setSelectedItem={setSelectedItem}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.manageTable__pagination}>
        <Pagination
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          page={page}
          lastPage={lastPage}
        />
      </div>
    </div>
  )
}

export default ManageTable
