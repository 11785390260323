import React from "react";
import clsx from "clsx";
//styles
import styles from "./styles.module.scss";

const Input = ({
  label,
  placeholder = "",
  type = "text",
  classes,
  classesInner,
  error,
  id,
  name,
  onChange,
  value,
}) => {
  return (
    <div className={clsx(styles.input, classesInner)}>
      {label && <h4>{label}</h4>}
      <input
        type={type}
        placeholder={placeholder}
        className={clsx(styles.input__main, classes, { [styles.error]: error })}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Input;
