import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
//layouts
import { Manage } from 'layouts'
//components
import { Header, Statistic } from 'components'
// utils
import { fetchRequest } from 'utils'
//styles
import styles from './styles.module.scss'

const Statistics = () => {
  const [statistics, setStatistics] = useState({})
  const admin = useSelector((state) => state.adminsData.superAdmin)
  console.log('adminFromRedux', admin)

  const fetchGetStatistics = async () => {
    const response = await fetchRequest('api/admin/get-statistics', 'GET')
    if (response.status === 200) {
      setStatistics(response.statistic)
    }
  }

  useEffect(() => {
    fetchGetStatistics()
  }, [])

  return (
    <Manage>
      <Header title='General statistics' />
      <div className={styles.statistics}>
        <div className={styles.statistics__box}>
          <Statistic label='Total users' value={statistics?.totalUsers} />
          <Statistic label='Sсhools' value={statistics?.totalSchools} />
          <Statistic label='Instructors' value={statistics?.totalInstructors} />
          <Statistic label='Students' value={statistics?.totalStudents} />
        </div>
      </div>
      <br />
      <br />
      <br />
      <Header title='Lessons' />
      <div className={styles.statistics}>
        <div className={styles.statistics__box}>
          <Statistic label='Total' value={statistics?.totalLessons} />
          <Statistic
            label='Completed'
            value={statistics?.totalCompletedLessons}
          />
          <Statistic label='Planned' value={statistics?.totalPlannedLessons} />
          <Statistic label='Booked' value={statistics?.totalBookedLessons} />
        </div>
      </div>
      {/* {admin?.super && (
        <div className={styles.balanceContainer}>
          <Header title='Balance' />
          <p className={styles.balance}>$ {admin.balance.toFixed(2)}</p>
        </div>
      )} */}
    </Manage>
  )
}

export default Statistics
