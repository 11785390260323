import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchRequest } from 'utils'

const usePagination = (url, query) => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [lastPage, setLastPage] = useState(null)
  const [numPage, setNumPage] = useState('1')
  const [name, setName] = useState(query)
  const [error, setError] = useState({ error: false, message: null })

  const queryString = require('query-string')

  const location = useLocation()
  const navigate = useNavigate()

  const { page = numPage } = queryString.parse(location.search)

  const getData = async (page) => {
    const response = await fetchRequest(
      `${url}?&page=${page}&name=${name ? name : ''}&login=${name ? name : ''}`,
      'GET'
    )
    const data =
      response?.reports?.reports ||
      response?.profiles?.profiles ||
      response?.logs
    const lastPage =
      response?.reports?.totalCount ||
      response?.profiles?.totalCount ||
      response?.totalCount ||
      1

    if (response.status === 200 && data) {
      setIsLoading(false)
      setData(data)
      setLastPage(lastPage)
    } else {
      setIsLoading(false)
      setError({ error: true, message: response.statusText })
    }
  }

  const initialPage = (page) => {
    const parsed = queryString.parse(location.search)
    parsed.page = page

    const stringified = queryString.stringify(parsed)
    navigate({
      pathname: location.pathname,
      search: `?${stringified}`,
    })
    getData(page)
  }

  useEffect(() => {
    initialPage(page)
  }, [])

  useEffect(() => {
    initialPage('1')
  }, [name])

  const incrementPage = (page) => {
    if (+page == lastPage) {
      return initialPage(lastPage)
    }
    initialPage(+page + 1)
  }

  const decrementPage = (page) => {
    if (+page == 1) {
      return initialPage(1)
    }
    initialPage(+page - 1)
  }

  return {
    data,
    isLoading,
    error,
    page,
    lastPage,
    decrementPage,
    incrementPage,
    setData,
    setNumPage,
    setName,
  }
}

export default usePagination
