import * as yup from "yup";

const validationValues = {
  login: yup.string().min(6, "Too short!").required("Required!"),
  password: yup.string().min(8, "Too short!").required("Password is required"),
  passwordConfirmation: yup
    .string()
    .min(8, "Too short!")
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required!"),
};

export default validationValues;
