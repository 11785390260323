import React from "react";
import { useNavigate } from "react-router";
//components
import { CustomAlert } from "components";

const BanModal = ({
  showModal,
  closeModal,
  isOpen,
  title,
  text,
  titleBtn,
  onClick,
}) => {
  return (
    <CustomAlert
      title={title}
      text={text}
      titleBtn={titleBtn}
      showModal={showModal}
      closeModal={closeModal}
      isOpen={isOpen}
      onClick={onClick}
    />
  );
};

export default BanModal;
