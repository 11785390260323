import React from 'react'
//components
import { Input } from 'components'
//assets
import { Search } from 'assets'
//styles
import styles from './styles.module.scss'

const SearchInput = ({ value, onChange, onClickSearch }) => {
  return (
    <div className={styles.search}>
      <Input
        value={value}
        onChange={onChange}
        placeholder='Search'
        classes={styles.search__input}
        classesInner={styles.search__inputInner}
      />
      <button onClick={onClickSearch}>
        <Search />
      </button>
    </div>
  )
}

export default SearchInput
