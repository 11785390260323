import React from 'react'
import { NavLink } from 'react-router-dom'
//consts
import { menu, menuAdmin } from 'consts'
// assets
import { MiniLogo } from '../../assets'
//styles
import styles from './styles.module.scss'

const Sidebar = ({ onClick, admin }) => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar__row}>
        <div className={styles.sidebar__logo}>
          <MiniLogo />
        </div>
        <h4>Admin tools</h4>
        {admin?.admin?.super ? (
          <div className={styles.sidebar__menu}>
            {menu.map(({ Icon, to, title }) => (
              <NavLink key={to} to={to}>
                <Icon />
                {title}
              </NavLink>
            ))}
          </div>
        ) : (
          <div className={styles.sidebar__menu}>
            {menuAdmin.map(({ Icon, to, title }) => (
              <NavLink key={to} to={to}>
                <Icon />
                {title}
              </NavLink>
            ))}
          </div>
        )}
      </div>
      <div className={styles.sidebar__profile}>
        <h4>{admin?.admin?.super ? 'Super Admin' : admin?.admin?.login}</h4>
        <button onClick={onClick}>Log out</button>
      </div>
    </div>
  )
}

export default Sidebar
