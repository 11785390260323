import React from 'react'
//components
import { Button } from 'components'
//styles
import styles from './styles.module.scss'
import { DeleteModal } from 'modals'
import { useToggleModal } from 'hooks'
import { fetchRequest } from 'utils'
import { toast } from 'react-toastify'
import { BASE_URL } from 'config'

const Post = ({ post, getPosts }) => {
  const deleteModal = useToggleModal()

  const deletePost = async () => {
    await fetchRequest(`api/admin/deletePost?id=${post._id}`, 'DELETE')
    getPosts()
    toast.info('Post successfully removed!')
  }

  console.log('post', post)

  return (
    <>
      <div className={styles.post}>
        <div className={styles.post__cover}>
          <div className={styles.post__item}>
            <p>Post cover</p>
            <div className={styles.post__photo}>
              {post.urlType === 'video/media' ? (
                <p>VIDEO</p>
              ) : (
                <img src={post.url} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.post__info}>
          <div className={styles.post__item}>
            <p>Post title</p>
            <div className={styles.post__value}>{post.title}</div>
          </div>
          <div className={styles.post__item}>
            <p>Post ID</p>
            <div className={styles.post__value}>{post._id}</div>
          </div>
          <div className={styles.post__deleteBtn}>
            <Button
              title='Delete post'
              classes={styles.post__delete}
              onClick={deleteModal.showModal}
            />
          </div>
        </div>
      </div>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete post'}
          text={'Are you sure you want to delete post?'}
          onClick={deletePost}
        />
      )}
    </>
  )
}

export default Post
