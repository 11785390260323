import React, { useState } from 'react'
//components
import { Button, Tag } from 'components'
//styles
import styles from './styles.module.scss'
import moment from 'moment'
import { DeleteModal } from 'modals'
import { useToggleModal } from 'hooks'
import { toast } from 'react-toastify'
import { fetchRequest } from 'utils'
import { TokenLS } from 'helpers'

const ReportRow = ({ item, haldleDelete }) => {
  const [copySuccess, setCopySuccess] = useState('')
  const [duration, setDuration] = useState(1)
  const deleteModal = useToggleModal()

  const deleteReport = async () => {
    const response = await fetchRequest(
      `api/admin/deleteReport?id=${item._id}`,
      'DELETE'
    )

    if (response.status === 200) {
      deleteModal.closeModal()
      haldleDelete(item._id)
      toast.info('Successfully removed!')
    }
  }

  const copyName = () => {
    const text = item?.subjectName
    navigator.clipboard && navigator.clipboard.writeText(text)
    setCopySuccess('Copied!')

    const token = TokenLS.getToken()
    console.log('token', token)
    setTimeout(() => {
      setDuration(0)
    }, 2000)
    setDuration(1)
  }

  return (
    <>
      <tr className={styles.reportRow}>
        <td className={styles.reportRow__date}>
          {moment(item?.createdAt).format('L')}
        </td>
        {document.queryCommandSupported('copy') && (
          <td className={styles.reportRow__copyTd}>
            <Tag
              type='instructor'
              title={item?.creator?.login}
              classes={styles.reportRow__copy}
              onClick={copyName}
            />
            <p
              className={
                duration === 0
                  ? styles.reportRow__copyField1
                  : styles.reportRow__copyField
              }
            >
              {copySuccess}
            </p>
          </td>
        )}
        <td>
          <Tag type='student' title={item?.student?.login} />
        </td>
        <td>{item?.post}</td>
        <td>
          <Button
            title='Delete'
            darkStyle
            classes={styles.reportRow__btn}
            onClick={deleteModal.showModal}
          />
        </td>
      </tr>
      <tr className={styles.reportRow__text}>
        <td colSpan='5'>
          <p>{item?.message}</p>
        </td>
      </tr>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete report'}
          text={'Are you sure you want to delete report?'}
          onClick={deleteReport}
        />
      )}
    </>
  )
}

export default ReportRow
