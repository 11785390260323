import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import { AdminItem, Button, Header, Input } from "components";
//validation
import VALIDATIONS from "validation";
//helpers
import { TokenLS } from "helpers";
// libs
import { useFormik } from "formik";
import { toast } from "react-toastify";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/actions/profileData";
import { deleteAdminThunk, getAdminsThunk } from "redux/actions/adminData";
//utils
import { fetchRequest } from "utils";
//styles
import styles from "./styles.module.scss";
import { useToggleModal } from "hooks";
import { DeleteModal } from "modals";

const Settings = () => {
  const dispatch = useDispatch();

  const { showModal, closeModal, isShow } = useToggleModal();

  const { isLoading } = useSelector((state) => state.profileData);
  const { admins } = useSelector((state) => state.adminsData);

  const [disabled, setDisabled] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  // add admins
  const formik = useFormik({
    validationSchema: VALIDATIONS.createAdmin,
    initialValues: {
      login: "",
      password: "",
      confirm: "",
    },

    onSubmit: async (values) => {
      dispatch(setLoading(true));
      setDisabled(true);
      const body = {
        login: values.login,
        password: values.password,
      };

      const response = await fetchRequest(
        "api/admin/createAdmin",
        "POST",
        body
      );

      if (response && response.success) {
        toast.info("admin successfully created!");
        formik.resetForm();
        dispatch(getAdminsThunk());
      } else {
        toast.error("admin dont created!");
      }
      dispatch(setLoading(false));
      setDisabled(false);
    },
  });

  // select one admin
  const selectAdmin = (admin) => {
    showModal();
    setSelectedAdmin(admin);
  };

  // delete admin
  const deleteAdmin = async () => {
    try {
      dispatch(deleteAdminThunk(selectedAdmin._id));
      closeModal();
      toast.info("admin successfully deleted!");
    } catch (e) {
      toast.error("admin don't deleted!");
    }
  };

  return (
    <>
      <div className={styles.settings}>
        <div className={styles.settings__create}>
          <Header title="Create admin" classes={styles.settings__header} />
          <div className={styles.settings__form}>
            <Input
              label="Login"
              placeholder="login"
              id="login"
              name="login"
              type="login"
              onChange={formik.handleChange}
              value={formik.values.login}
              error={formik.errors.login}
            />
            <Input
              label="Password"
              placeholder="********"
              type="password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              error={formik.errors.password}
            />
            <Input
              label="Confirm password"
              placeholder="********"
              type="password"
              id="confirm"
              name="confirm"
              onChange={formik.handleChange}
              value={formik.values.confirm}
              error={formik.errors.confirm}
            />
            <Button
              title="Create Profile"
              disabled={!formik.isValid || !formik.dirty || disabled}
              classes={styles.settings__btn}
              onClick={formik.handleSubmit}
              loading={isLoading}
            />
          </div>
        </div>
        <div className={styles.settings__admins}>
          <Header title="Admins" classes={styles.settings__header} />
          <h4>Login</h4>
          <div className={styles.settings__adminsList}>
            {admins.map((admin) => {
              return (
                <AdminItem
                  key={admin._id}
                  admin={admin}
                  onClick={selectAdmin}
                />
              );
            })}
          </div>
        </div>
      </div>
      {isShow && (
        <DeleteModal
          showModal={showModal}
          closeModal={closeModal}
          isOpen={isShow}
          title={"Delete admin"}
          text={"Are you sure you want to delete admin?"}
          onClick={deleteAdmin}
        />
      )}
    </>
  );
};

export default Settings;
