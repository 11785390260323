import React from 'react'
//components
import { Button } from 'components'

//styles
import styles from './styles.module.scss'
import clsx from 'clsx'

const BanDelete = ({ onClickBan, onClickDelete, banTitle }) => {
  return (
    <>
      <Button
        title={banTitle}
        classes={clsx(
          banTitle === 'Unban'
            ? styles.banDelete__btn_active
            : styles.banDelete__btn
        )}
        emptyStyle
        onClick={onClickBan}
      />
      {/* <Button
        title='Delete'
        classes={styles.banDelete__btn}
        darkStyle
        onClick={onClickDelete}
      /> */}
    </>
  )
}

export default BanDelete
