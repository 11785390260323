import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
//layouts
import { Manage } from 'layouts'
//sections
import { Posts } from 'sections'
//components
import { BackBtn, Profile } from 'components'
import styles from './styles.module.scss'
const Log = (props) => {
  const location = useLocation()
  const { item } = location.state
  console.log('props.location.state.item', item)
  const [response, setResponse] = useState([])
  const [request, setRequest] = useState([])

  // const arr = []
  useEffect(() => {
    getResponse(item.response)
    getRequest(item.request)
  }, [])

  function getResponse(obj) {
    getProp(obj)

    function getProp(o) {
      for (var prop in o) {
        if (typeof o[prop] === 'object') {
          getProp(o[prop])
        } else {
          const a = `${prop} : ${o[prop]}`
          setResponse((prev) => [...prev, a])
        }
      }
    }
  }
  function getRequest(obj) {
    getProp(obj)

    function getProp(o) {
      for (var prop in o) {
        if (typeof o[prop] === 'object') {
          getProp(o[prop])
        } else {
          const a = `${prop} : ${o[prop]}`

          setRequest((prev) => [...prev, a])
        }
      }
    }
  }

  console.log(response)
  return (
    <Manage>
      <div>
        <BackBtn title='Back to Logs' />
      </div>
      <div className={styles.inner}>
        <p className={styles.dataUser}>URL: "{item.request.url}"</p>
        <p className={styles.dataUser}>User id: {item._id}</p>
        <p className={styles.data}>{`Response: {`}</p>
        {response.map((value) => (
          <p className={styles.dataHandle}>{value}</p>
        ))}
        <p className={styles.data}>{`}`}</p>
        <p className={styles.data}>{`Request: {`}</p>
        {request.map((value) => (
          <p className={styles.dataHandle}>{value}</p>
        ))}
        <p className={styles.data}>{`}`}</p>
      </div>
    </Manage>
  )
}

export default Log
