import React from 'react'
//layouts
import { Manage } from 'layouts'
//sections
import { Posts } from 'sections'
//components
import { BackBtn, ProfileSchool } from 'components'
// assets
import styles from './styles.module.scss'

const School = () => {
  return (
    <Manage>
      <div>
        <BackBtn title='Back to Manage School' />
      </div>
      <ProfileSchool />
      <div className={styles.posts}>
        <Posts type='school' />
      </div>
    </Manage>
  )
}

export default School
