import React from "react";
import clsx from "clsx";
//styles
import styles from "./styles.module.scss";

const Header = ({ children, title, classes }) => {
  return (
    <div className={clsx(styles.header, classes)}>
      <h2>{title}</h2>
      {children}
    </div>
  );
};

export default Header;
