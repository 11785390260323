import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { Button, Input } from 'components'
//helpers
import { TokenLS } from 'helpers'
// libraries
import { useFormik } from 'formik'
// utils
import { fetchRequest } from 'utils'
// redux
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from 'redux/actions/profileData'
// validations
import VALIDATIONS from 'validation'
// assets
import { Logo } from '../../assets'
import styles from './styles.module.scss'

const SignIn = () => {
  const navigate = useNavigate()
  const { isLoading } = useSelector((state) => state.profileData)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: VALIDATIONS.loginSchema,
    initialValues: {
      login: '',
      password: '',
    },

    onSubmit: async (values) => {
      dispatch(setLoading(true))
      setDisabled(true)
      const body = {
        login: values.login,
        password: values.password,
      }

      const response = await fetchRequest('api/admin/login', 'POST', body)

      if (response && response.success) {
        TokenLS.setToken(response.accessToken)
        navigate('/')
      } else {
        setError('Login or password is not valid')
      }
      dispatch(setLoading(false))
      setDisabled(false)
    },
  })

  return (
    <div className={styles.signIn}>
      <div className={styles.signIn__inner}>
        <div className={styles.signIn__logo}>
          <Logo />
        </div>
        <div className={styles.signIn__form}>
          <Input
            label='Login'
            placeholder='Typing login'
            id='login'
            name='login'
            type='login'
            onChange={formik.handleChange}
            value={formik.values.login}
            error={formik.errors.login}
          />
          <Input
            label='Password'
            placeholder='Typing password'
            type='password'
            id='password'
            name='password'
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />

          {error && <p className={styles.signIn__error}>{error}</p>}

          <Button
            type='submit'
            title={'Login'}
            classes={styles.signIn__btn}
            onClick={formik.handleSubmit}
            loading={isLoading}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}

export default SignIn
