export const instructorHeader = [
  'Registration date',
  'Instructor',
  'Phone',
  'Number of students',
  '',
]

export const schoolHeader = [
  'Registration date',
  'School',
  'Phone',
  'Number of instructors',
  '',
]

export const logHeader = ['Date', 'Url', 'Id', 'Data']

export const studentHeader = [
  'Registration date',
  'Student name',
  'Phone',
  'Completed lessons ',
  '',
]

export const reportHeader = [
  'Complaint Date',
  'School name/Instructor name',
  'Student Name',
  'Post ID',
  '',
]
