import React from "react";
import { useNavigate } from "react-router";
//components
import { CustomAlert } from "components";
//helpers
import { TokenLS } from "../../helpers";

const LogoutModal = ({ showModal, closeModal, isOpen }) => {
  const navigate = useNavigate();

  const logout = () => {
    TokenLS.removeToken();
    navigate("/signIn");
  };

  return (
    <CustomAlert
      title={"Log out"}
      text={"Are you sure you want to log out?"}
      titleBtn="Log out"
      showModal={showModal}
      closeModal={closeModal}
      isOpen={isOpen}
      onClick={logout}
    />
  );
};

export default LogoutModal;
