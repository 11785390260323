import React, { useEffect, useState } from 'react'
//layouts
import { Manage } from 'layouts'
//components
import { Header, ReportNotification } from 'components'
//sections
import { ReportTable } from 'sections'
//consts
import { reportHeader } from 'consts'
import { useToggleModal } from 'hooks'
import { DeleteModal } from 'modals'
import { fetchRequest } from 'utils'
import usePagination from 'hooks/usePagination'

const Reports = () => {
  // const { showModal, closeModal, isShow } = useToggleModal()

  return (
    <>
      <Manage>
        <Header title='Reports'>{/* <ReportNotification /> */}</Header>
        <ReportTable headers={reportHeader} />
      </Manage>
    </>
  )
}

export default Reports
