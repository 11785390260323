import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router'
// hooks
import { useToggleModal } from 'hooks'
// modals
import { BanModal, DeleteModal } from 'modals'
// utils
import { fetchRequest } from 'utils'
// config
import { BASE_URL } from 'config'
//components
import { BanDelete } from 'components'
//styles
import { School } from 'assets'
import styles from './styles.module.scss'

const ProfileSchool = () => {
  const deleteModal = useToggleModal()
  const banModal = useToggleModal()
  const [school, setSchool] = useState(null)
  const [students, setStudents] = useState(0)

  const { id } = useParams()
  const navigate = useNavigate()

  const onRoute = () => {
    navigate(-1)
  }

  const getSchool = async () => {
    const response = await fetchRequest(
      `api/admin/schoolDetails?id=${id}`,
      'GET'
    )
    if (response.status === 200) {
      setSchool(response.school)
      setStudents(response.school.students)
    }
  }

  useEffect(() => {
    getSchool()
  }, [])

  const deleteSchool = async () => {
    const response = await fetchRequest(
      `api/admin/delete/school?id=${id}`,
      'DELETE'
    )

    if (response && response.success) {
      deleteModal.closeModal()
      onRoute()
      toast.info('School successfully removed!')
    }
  }

  const instructorBanToggle = async () => {
    const body = {
      id: id,
    }

    if (!school?.banned) {
      const response = await fetchRequest(
        `api/admin/ban/school?id=${id}`,
        'POST',
        body
      )

      if (response && response.success) {
        banModal.closeModal()
        getSchool()
        toast.info('School successfully banned!')
      }
    } else {
      const response = await fetchRequest(`api/admin/ban/school`, 'POST', body)

      if (response && response.success) {
        banModal.closeModal()
        getSchool()
        toast.info('School successfully unbanned!')
      }
    }
  }

  const min = () => {
    if (students === 0) {
      return
    }
    setStudents((prev) => prev - 1)
  }

  const plus = () => {
    setStudents((prev) => prev + 1)
  }

  const save = async () => {
    const response = await fetchRequest(
      `api/admin/schoolUpdate/?id=${id}`,
      'POST',
      {
        students: students,
      }
    )
    if (response.status === 200) {
      toast.info('Saved')
    } else {
      toast.error('Not Saved')
    }
  }

  console.log('students', students)

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.profile__row}>
          <div className={styles.profile__avatar}>
            {/* <img
              style={{ width: '100%', height: '100%' }}
              src={school?.photo === '' ? null : school?.photo}
              alt={school?.cover}
            /> */}
            {!school?.creator?.photo ? (
              <School />
            ) : (
              <img
                style={{ width: '100%', height: '100%' }}
                src={school?.photo === '' ? null : school?.photo}
                alt={school?.creator?.photo}
              />
            )}
          </div>
          <div className={styles.profile__info}>
            <h2>{school?.login}</h2>
            <h4>{school?.phone}</h4>
          </div>
        </div>
        <p>Total posts: {school?.posts?.length ? school?.posts?.length : 0}</p>
        <div>
          <BanDelete
            onClickDelete={deleteModal.showModal}
            onClickBan={banModal.showModal}
            banTitle={school?.banned ? 'Unban' : 'Ban'}
          />
        </div>
      </div>
      <div className={styles.info}>
        <div>
          <p className={styles.info__title}>Address</p>
          <p className={styles.info__address}>
            {school?.address ? school?.address : 'address'}
          </p>
        </div>
        <div>
          <p className={styles.info__title}>Amount of instructors</p>
          <p className={styles.info__description}>
            {school?.instructors.length}
          </p>
        </div>
        <div>
          <p className={styles.info__title}>Number of students</p>
          <div className={styles.info__descriptionBox}>
            <div className={styles.info__min} onClick={min}>
              <p className={styles.info__minIcon}>-</p>
            </div>
            <p className={styles.info__description}>{students}</p>
            <div className={styles.info__plus} onClick={plus}>
              <p className={styles.info__plusIcon}>+</p>
            </div>
          </div>
          <div className={styles.info__saveWrapper}>
            <div className={styles.info__saveContainer} onClick={save}>
              <p>Save</p>
            </div>
          </div>
        </div>
        <div>
          <p className={styles.info__title}>Completed lessons </p>
          <p className={styles.info__description}>{school?.lessons}</p>
        </div>
      </div>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete school'}
          text={'Are you sure you want to delete school?'}
          onClick={deleteSchool}
        />
      )}
      {banModal.isShow && (
        <BanModal
          showModal={banModal.showModal}
          closeModal={banModal.closeModal}
          isOpen={banModal.isShow}
          title={`${school.banned ? 'Unban' : 'Ban'} school`}
          text={`Are you sure you want to ${
            school.banned ? 'unban' : 'ban'
          } school?`}
          titleBtn={`${school.banned ? 'Unban' : 'Ban'}`}
          onClick={instructorBanToggle}
        />
      )}
    </>
  )
}

export default ProfileSchool
