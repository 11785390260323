import {
  Reports,
  Instructor,
  Settings,
  Statistics,
  Students,
  Logs,
} from 'assets'

export const menu = [
  {
    Icon: Instructor,
    title: 'Manage Instructors',
    to: '/manageInstructors',
  },
  {
    Icon: Students,
    title: 'Manage Students',
    to: '/manageStudents',
  },
  {
    Icon: Reports,
    title: 'Reports',
    to: '/reports',
  },
  {
    Icon: Statistics,
    title: 'Statistics',
    to: '/statistics',
  },
  {
    Icon: Logs,
    title: 'Logs',
    to: '/logs',
  },
  {
    Icon: Settings,
    title: 'Settings',
    to: '/settings',
  },
]

export const menuAdmin = [
  {
    Icon: Instructor,
    title: 'Manage Instructors',
    to: '/manageInstructors',
  },
  {
    Icon: Students,
    title: 'Manage Students',
    to: '/manageStudents',
  },
  {
    Icon: Reports,
    title: 'Reports',
    to: '/reports',
  },
  {
    Icon: Statistics,
    title: 'Statistics',
    to: '/statistics',
  },
]
