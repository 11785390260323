import React, { useEffect } from "react";
//navigation
import Navigation from "navigation";
import { useDispatch } from "react-redux";
import { getAdminsThunk } from "redux/actions/adminData";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAdminsThunk());
  }, []);

  return <Navigation />;
};

export default App;
