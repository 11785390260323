import React from 'react'
//layouts
import { Manage } from 'layouts'
//sections
import { Posts } from 'sections'
//components
import { BackBtn, Profile } from 'components'

const Instructor = () => {
  return (
    <Manage>
      <div>
        <BackBtn title='Back to Manage Instructors' />
      </div>
      <Profile />
      <Posts type='instructor' />
    </Manage>
  )
}

export default Instructor
