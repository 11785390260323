import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// libs
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
//pages
import {
  Dashboard,
  NotFound,
  SignIn,
  ManageInstructors,
  ManageStudents,
  Reports,
  Statistics,
  Settings,
  Student,
  Instructor,
  School,
  Logs,
  Log,
} from 'pages'
//components
import { PrivateRoute } from './components'
import { SchoolsTable } from 'sections'

const Navigation = () => {
  return (
    <>
      <Routes>
        <Route path='signIn' element={<SignIn />} />
        <Route path='/' element={<PrivateRoute component={Dashboard} />}>
          <Route index element={<Navigate to='manageInstructors' />} />
          <Route path='manageInstructors' element={<ManageInstructors />} />
          <Route path='manageInstructors/:id' element={<Instructor />} />
          <Route path='manageSchools' element={<SchoolsTable />} />
          <Route path='manageSchools/:id' element={<School />} />
          <Route path='manageStudents' element={<ManageStudents />} />
          <Route path='manageStudents/:id' element={<Student />} />
          <Route path='Reports' element={<Reports />} />
          <Route path='statistics' element={<Statistics />} />
          <Route path='logs' element={<Logs />} />
          <Route path='logs/:id' element={<Log />} />
          <Route path='settings' element={<Settings />} />
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default Navigation
