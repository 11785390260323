import { useState } from "react";

export const useToggleModal = () => {
  const [isShow, setIsShow] = useState(false);

  const showModal = () => {
    setIsShow(true);
  };

  const closeModal = () => {
    setIsShow(false);
  };

  return {
    isShow,
    closeModal,
    showModal,
  };
};
