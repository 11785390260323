import { DELETE_ADMIN, SET_ADMINS } from "../../actionTypes/adminData.js";

import fetchRequest from "utils/fetchRequest.js";

// action
export const setAdmins = (payload) => ({ type: SET_ADMINS, payload });
export const deleteAdmin = (payload) => ({ type: DELETE_ADMIN, payload });

// thunk
export const getAdminsThunk = () => async (dispatch) => {
  const response = await fetchRequest("api/admin/admins", "GET");

  if (response && response.status === 200) {
    dispatch(setAdmins(response.admins));
  }
};

export const deleteAdminThunk = (id) => async (dispatch) => {
  const response = await fetchRequest(
    `api/admin/deleteAdmin?id=${id}`,
    "DELETE"
  );

  if (response && response.success) {
    dispatch(deleteAdmin(response));
  }
};
