import {
  GET_INSTRUCTOR,
  GET_INSTRUCTORS,
  UPDATE_INSCTRUCTOR,
} from 'redux/actionTypes/instructorData'

export const getInstructor = (payload) => ({ type: GET_INSTRUCTOR, payload })
export const getInstructors = (payload) => ({ type: GET_INSTRUCTORS, payload })
export const updateInstructor = (payload) => ({
  type: UPDATE_INSCTRUCTOR,
  payload,
})
