import React, { forwardRef } from 'react'
import clsx from 'clsx'
//styles
import styles from './styles.module.scss'

const Tag = ({ type, title = 'Tom Jerry', onClick, classes }) => {
  return (
    <div
      className={clsx(
        styles.tag,
        {
          [styles.student]: type === 'student',
          [styles.instructor]: type === 'instructor',
        },
        classes
      )}
      onClick={onClick}
    >
      {title}
    </div>
  )
}

export default Tag
