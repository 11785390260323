import { Navigate } from "react-router-dom";
//helpers
import { TokenLS } from "helpers";

const PrivateRoute = ({ component: RouteComponent }) => {
  const token = TokenLS.getToken();
  return token ? <RouteComponent /> : <Navigate to="/signIn" />;
};

export default PrivateRoute;
