import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
//assets
import { Eye } from 'assets'
// styles
import styles from './styles.module.scss'
const LogRow = ({ item }) => {
  console.log('item', item)
  const navigate = useNavigate()

  const onRoute = () => {
    navigate(`/logs/${item._id}`, {
      state: {
        item: item,
      },
    })
  }

  return (
    <tr className={styles.log}>
      <td>{moment(item.createdAt).format('YYYY/MM/DD hh:mm a')}</td>
      <td>{item.request.url}</td>
      <td>{item._id}</td>
      <td>
        Status code: {item.response.statusCode} <br />{' '}
        <button onClick={onRoute}>
          View all information <Eye />
        </button>
      </td>
    </tr>
  )
}

export default LogRow
