import React from 'react'
//components
import { Pagination, ReportRow } from 'components'
//styles
import styles from './styles.module.scss'
import usePagination from 'hooks/usePagination'

const ReportTable = ({ headers }) => {
  const { incrementPage, decrementPage, page, lastPage, data, setData } =
    usePagination('api/admin/reports')

  const haldleDelete = (id) => {
    const arr = data.filter((item) => item._id !== id)
    setData(arr)
  }

  return (
    <div className={data !== [] ? styles.reportTable : styles.reportTableDown}>
      <table>
        <thead>
          <tr>
            {headers.map((title) => (
              <td key={title}>{title}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((value) => (
              <ReportRow
                key={value._id}
                item={value}
                haldleDelete={haldleDelete}
              />
            ))}
        </tbody>
      </table>
      <div className={styles.manageTable__pagination}>
        <Pagination
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          page={page}
          lastPage={lastPage}
        />
      </div>
    </div>
  )
}

export default ReportTable
