import {
  GET_INSTRUCTOR,
  GET_INSTRUCTORS,
  UPDATE_INSCTRUCTOR,
} from 'redux/actionTypes/instructorData'

const initialState = {
  instructor: {},
  instructors: [],
}

export const instructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTRUCTOR:
      return { ...state, instructor: { ...action.payload } }
    case GET_INSTRUCTORS:
      return { ...state, instructors: [...action.payload] }
    case UPDATE_INSCTRUCTOR:
      return {
        ...state,
        instructor: { ...state.instructor, ...action.payload },
      }
    default:
      return state
  }
}
