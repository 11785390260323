import React from 'react'
import clsx from 'clsx'
// libs
import { Spinner } from 'react-activity'
import 'react-activity/dist/Spinner.css'
//styles
import styles from './styles.module.scss'

const Button = ({
  title,
  onClick,
  disabled,
  classes,
  emptyStyle,
  darkStyle,
  type,
  loading,
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        {
          [styles.disabled]: disabled,
          [styles.emptyStyle]: emptyStyle,
          [styles.darkStyle]: darkStyle,
        },
        classes
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {loading ? <Spinner size={20} /> : title}
    </button>
  )
}

export default Button
