import React, { useEffect } from "react";
import Modal from "react-modal";
//styles
import styles from "./styles.module.scss";

const ModalMain = ({ children, showModal, closeModal, classes, isOpen }) => {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      className={classes}
      overlayClassName={styles.overlay}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      closeTimeoutMS={300}
    >
      {children}
    </Modal>
  );
};

export default ModalMain;
