import React, { useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router'
//layouts
import { Manage } from 'layouts'
//section
import { InstructorsTable, SchoolsTable } from 'sections'
//components
import { Button, Header, SearchInput } from 'components'
//const
import { instructorHeader, schoolHeader } from 'consts'
// styles
import clsx from 'clsx'
import styles from './styles.module.scss'

const ManageInstructors = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryString = require('query-string')
  const [showInfo, setShowInfo] = useState(() => {
    const { type = 'instructors' } = queryString.parse(location.search)
    return type
  })
  const [query, setQuery] = useState('')

  const ref = useRef(null)

  const toggleContent = (type) => {
    setShowInfo(type)
    const parsed = queryString.parse(location.search)
    parsed.type = type

    const stringified = queryString.stringify(parsed)

    navigate({
      pathname: location.pathname,
      search: `?${stringified}`,
    })
  }

  const handleSearchByQuery = () => {
    ref.current.handleSearchByQuery(query)
  }

  return (
    <>
      <Manage>
        <Header
          title={
            showInfo === 'schools' ? 'Manage Schools' : 'Manage Instructors'
          }
        >
          <div className={clsx(styles.btnsContainer)}>
            <Button
              title='Instructors'
              classes={clsx(
                showInfo === 'instructors'
                  ? styles.ActiveInstructors
                  : styles.instructors
              )}
              onClick={() => toggleContent('instructors')}
            />
            <Button
              title='Schools'
              classes={clsx(
                showInfo === 'schools' ? styles.ActiveSchools : styles.schools
              )}
              onClick={() => toggleContent('schools')}
            />
          </div>
          <SearchInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onClickSearch={handleSearchByQuery}
          />
        </Header>
        {showInfo === 'instructors' && (
          <InstructorsTable ref={ref} headers={instructorHeader} />
        )}
        {showInfo === 'schools' && (
          <SchoolsTable ref={ref} headers={schoolHeader} />
        )}
      </Manage>
    </>
  )
}

export default ManageInstructors
