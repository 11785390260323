import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { toast } from 'react-toastify'
//components
import { Pagination, UserRow } from 'components'
// hooks
import usePagination from 'hooks/usePagination'
import { useToggleModal } from 'hooks'
// modals
import { BanModal, DeleteModal } from 'modals'
// utils
import { fetchRequest } from 'utils'
//styles
import styles from './styles.module.scss'

const InstructorsTable = forwardRef(({ headers }, ref) => {
  const deleteModal = useToggleModal()
  const banModal = useToggleModal()
  const [selectedItem, setSelectedItem] = useState(null)

  const {
    incrementPage,
    decrementPage,
    page,
    lastPage,
    data,
    setData,
    setName,
  } = usePagination('api/admin/profiles/instructor', '')

  const deleteInstructor = async () => {
    const response = await fetchRequest(
      `api/admin/delete/instructor?id=${selectedItem._id}`,
      'DELETE'
    )

    if (response && response.success) {
      deleteModal.closeModal()
      haldleDelete(selectedItem._id)
      toast.info('Instructor successfully removed!')
    }
  }

  const instructorBanToggle = async () => {
    const body = {
      id: selectedItem._id,
    }

    if (!selectedItem.banned) {
      const response = await fetchRequest(
        `api/admin/ban/instructor`,
        'POST',
        body
      )

      if (response && response.success) {
        banModal.closeModal()
        setData(
          data.map((item) => {
            if (item._id === selectedItem._id) {
              return { ...item, banned: !item.banned }
            }
            return item
          })
        )
        toast.info('Instructor successfully banned!')
      }
    } else {
      const response = await fetchRequest(
        `api/admin/ban/instructor`,
        'POST',
        body
      )

      if (response && response.success) {
        banModal.closeModal()
        setData(
          data.map((item) => {
            if (item._id === selectedItem._id) {
              return { ...item, banned: !item.banned }
            }
            return item
          })
        )
        toast.info('Instructor successfully unbanned!')
      }
    }
  }
  console.log('selectedItem', selectedItem)

  const haldleDelete = (id) => {
    const arr = data.filter((item) => item._id !== id)
    setData(arr)
  }

  useImperativeHandle(ref, () => ({
    handleSearchByQuery(queryName) {
      setName(queryName)
    },
  }))

  return (
    <>
      <div className={styles.manageTable}>
        <div className={styles.manageTable__inner}>
          <table>
            <thead>
              <tr>
                {headers.map((title) => (
                  <td className={styles.manageTable__td} key={title}>
                    {title}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <UserRow
                  key={item._id}
                  item={item}
                  onClickDelete={deleteModal.showModal}
                  onClickBan={banModal.showModal}
                  setSelectedItem={setSelectedItem}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.manageTable__pagination}>
          <Pagination
            incrementPage={incrementPage}
            decrementPage={decrementPage}
            page={page}
            lastPage={lastPage}
          />
        </div>
      </div>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete instructor'}
          text={'Are you sure you want to delete instructor?'}
          onClick={deleteInstructor}
        />
      )}
      {banModal.isShow && (
        <BanModal
          showModal={banModal.showModal}
          closeModal={banModal.closeModal}
          isOpen={banModal.isShow}
          title={`${selectedItem.banned ? 'Unban' : 'Ban'} instructor`}
          text={`Are you sure you want to ${
            selectedItem.banned ? 'unban' : 'ban'
          } instructor?`}
          titleBtn={`${selectedItem.banned ? 'Unban' : 'Ban'}`}
          onClick={instructorBanToggle}
        />
      )}
    </>
  )
})

export default InstructorsTable
