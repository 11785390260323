import React from 'react'
import { useNavigate } from 'react-router-dom'
//components
import { BanDelete } from 'components'
//assets
import { Eye, Person, School } from 'assets'
//styles
import styles from './styles.module.scss'
import moment from 'moment'
import { BASE_URL } from 'config'

const UserRow = ({
  item,
  onClickDelete,
  onClickBan,
  setSelectedItem = null,
}) => {
  const navigate = useNavigate()

  const onRoute = () => {
    navigate(`/manageInstructors/${item._id}`)
  }
  const onRouteSchool = () => {
    navigate(`/manageSchools/${item._id}`)
  }

  const selectItemForDelete = () => {
    setSelectedItem(item)
    onClickDelete()
  }

  const selectItemForBun = () => {
    setSelectedItem(item)
    onClickBan()
  }

  const handlePhone = () => {
    const start = item.phone?.slice(0, 2)
    const second = item.phone?.slice(2, 5)
    const therd = item.phone?.slice(5, 8)
    const finish = item.phone?.slice(8)
    const phone = `${start} ${second} ${therd} ${finish}`
    return phone
  }

  console.log('photo', item)

  return (
    <tr className={styles.userRow}>
      <td className={styles.userRow__date}>
        {moment(item.createdAt).format('L')}
      </td>
      <td>
        <div className={styles.userRow__item}>
          <div className={styles.userRow__avatar}>
            {item?.photo && (
              <img
                style={{ width: '100%', height: '100%' }}
                src={item.photo}
                alt={item.photo}
              />
            )}
            {/* {item?.cover && (
              <img
                style={{ width: '100%', height: '100%' }}
                src={item.cover}
                alt={item.photo ?? item.cover}
              />
            )} */}
            {(item.accType === 'instructor' || item.accType === 'student') &&
              !item.photo && <Person />}
            {item.accType === 'school' && !item.photo && <School />}
          </div>
          <div className={styles.userRow__info}>
            <h4>{item.login ?? item.name}</h4>
            {item.accType === 'instructor' && (
              <button onClick={onRoute}>
                View all information <Eye />
              </button>
            )}
            {item.accType === 'school' && (
              <button onClick={onRouteSchool}>
                View all information <Eye />
              </button>
            )}
          </div>
        </div>
      </td>
      <td>{item.phone ? handlePhone() : 'no phone number'}</td>
      {item.accType === 'instructor' && (
        <td>{item.students ? item.students : 0}</td>
      )}
      {item.accType === 'school' && (
        <td>{item.instructors.length ? item.instructors.length : 0}</td>
      )}
      {item.accType === 'student' && (
        <td>{item.lessons.length ? item.lessons.length : 0}</td>
      )}
      <td>
        <BanDelete
          onClickDelete={selectItemForDelete}
          onClickBan={selectItemForBun}
          banTitle={item.banned ? 'Unban' : 'Ban'}
        />
      </td>
    </tr>
  )
}

export default UserRow
