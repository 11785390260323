import React from 'react'
//assets
import { Arrow } from 'assets'
//styles
import styles from './styles.module.scss'

const Pagination = ({ incrementPage, decrementPage, page, lastPage }) => {
  return (
    <div className={styles.pagination}>
      <button className={styles.left} onClick={() => decrementPage(page)}>
        <Arrow />
      </button>
      <p>
        {page} of {lastPage ? lastPage : 1}
      </p>
      <button onClick={() => incrementPage(page)}>
        <Arrow />
      </button>
    </div>
  )
}

export default Pagination
