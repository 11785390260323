import React from 'react'
import { useNavigate } from 'react-router-dom'
// assets
import { Back } from 'assets'
//styles
import styles from './styles.module.scss'

const BackBtn = ({ title }) => {
  const navigate = useNavigate()
  const onBack = () => {
    navigate(-1)
  }
  return (
    <button className={styles.backBtn} onClick={onBack}>
      <Back />
      {title}
    </button>
  )
}

export default BackBtn
