import React, { useEffect, useState } from 'react'
//layouts
import { Manage } from 'layouts'
//section
import { ManageTable } from 'sections'
// utils
import { fetchRequest } from 'utils'
//components
import { Header, SearchInput } from 'components'
//const
import { studentHeader } from 'consts'
import { useToggleModal } from 'hooks'
import { BanModal, DeleteModal } from 'modals'
import { toast } from 'react-toastify'
import usePagination from 'hooks/usePagination'

const ManageStudents = () => {
  const deleteModal = useToggleModal()
  const banModal = useToggleModal()
  const [query, setQuery] = useState('')

  const {
    incrementPage,
    decrementPage,
    page,
    lastPage,
    data,
    setData,
    setName,
  } = usePagination('api/admin/profiles/student', query)

  const [selectedItem, setSelectedItem] = useState(null)

  const deleteStudent = async () => {
    const response = await fetchRequest(
      `api/admin/delete/student?id=${selectedItem._id}`,
      'DELETE'
    )

    if (response && response.success) {
      deleteModal.closeModal()
      haldleDelete(selectedItem._id)
      toast.info('Student successfully removed!')
    }
  }

  const haldleDelete = (id) => {
    const arr = data.filter((item) => item._id !== id)
    setData(arr)
  }

  const studentBanToggle = async () => {
    const body = {
      id: selectedItem._id,
    }

    if (!selectedItem.banned) {
      const response = await fetchRequest(`api/admin/ban/student`, 'POST', body)

      if (response && response.success) {
        banModal.closeModal()
        setData(
          data.map((item) => {
            if (item._id === selectedItem._id) {
              return { ...item, banned: !item.banned }
            }
            return item
          })
        )
        toast.info('Student successfully banned!')
      }
    } else {
      const response = await fetchRequest(`api/admin/ban/student`, 'POST', body)

      if (response && response.success) {
        banModal.closeModal()
        setData(
          data.map((item) => {
            if (item._id === selectedItem._id) {
              return { ...item, banned: !item.banned }
            }
            return item
          })
        )
        toast.info('Student successfully unbanned!')
      }
    }
  }

  // useEffect(() => {
  //   studentBanToggle()
  // }, [])

  const handleSearchByQuery = () => {
    setName(query)
  }

  return (
    <>
      <Manage>
        <Header title='Manage Students'>
          <SearchInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onClickSearch={handleSearchByQuery}
          />
        </Header>
        <ManageTable
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          page={page}
          lastPage={lastPage}
          body={data}
          headers={studentHeader}
          onClickDelete={deleteModal.showModal}
          onClickBan={banModal.showModal}
          setSelectedItem={setSelectedItem}
          haldleDelete={haldleDelete}
        />
      </Manage>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete student'}
          text={'Are you sure you want to delete student?'}
          onClick={deleteStudent}
        />
      )}
      {banModal.isShow && (
        <BanModal
          showModal={banModal.showModal}
          closeModal={banModal.closeModal}
          isOpen={banModal.isShow}
          title={`${selectedItem.banned ? 'Unban' : 'Ban'} student`}
          text={`Are you sure you want to ${
            selectedItem.banned ? 'unban' : 'ban'
          } student?`}
          titleBtn={`${selectedItem.banned ? 'Unban' : 'Ban'}`}
          onClick={studentBanToggle}
        />
      )}
    </>
  )
}

export default ManageStudents
