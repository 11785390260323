import { useToggleModal } from 'hooks'
import { LogoutModal } from 'modals'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet } from 'react-router-dom'
// sections
import { Sidebar } from 'sections'
import { fetchRequest } from 'utils'
//styles
import styles from './styles.module.scss'

const Dashboard = () => {
  const adminModal = useToggleModal()
  const [admin, setAdmin] = useState(null)
  const dispatch = useDispatch()

  const getAdmin = async () => {
    const response = await fetchRequest('api/admin/info', 'GET')
    if (response.status === 200) {
      setAdmin(response)
      dispatch({ type: 'SET_SUPER_ADMIN', payload: response?.admin })
    }
  }

  useEffect(() => {
    getAdmin()
  }, [])

  console.log('admin', admin)

  return (
    <>
      <div className={styles.dashboard}>
        <Sidebar admin={admin} onClick={adminModal.showModal} />
        <div className={styles.dashboard__inner}>
          <Outlet />
        </div>
      </div>
      {adminModal.isShow && (
        <LogoutModal
          showModal={adminModal.showModal}
          closeModal={adminModal.closeModal}
          isOpen={adminModal.isShow}
        />
      )}
    </>
  )
}

export default Dashboard
