import React, { useEffect } from 'react'
import { useParams } from 'react-router'
// utils
import { fetchRequest } from 'utils'
//components
import { Post } from 'components'
// redux
import { getInstructor } from 'redux/actions/instructorData'
import { useDispatch, useSelector } from 'react-redux'
//styles
import styles from './styles.module.scss'

const Posts = ({ type }) => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const { instructor } = useSelector((value) => value.instructorData)
  const getPosts = async () => {
    const response = await fetchRequest(
      `api/admin/userPosts?id=${id}&type=${type}`,
      'GET'
    )
    dispatch(getInstructor(response))
  }

  useEffect(() => {
    getPosts()
  }, [])

  return (
    <div className={styles.posts}>
      {instructor.posts &&
        instructor.posts.map((value) => (
          <Post key={value._id} post={value} getPosts={getPosts} />
        ))}
    </div>
  )
}

export default Posts
