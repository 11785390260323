import React from 'react'
//layouts
import { Manage } from 'layouts'
//components
import { Header } from 'components'
// hooks
import usePagination from 'hooks/usePagination'
//components
import { Pagination, LogRow } from 'components'
//const
import { logHeader } from 'consts'
//styles
import styles from './styles.module.scss'

const Logs = () => {
  const { incrementPage, decrementPage, page, lastPage, data, setData } =
    usePagination('api/admin/get-logs')

  console.log('data', data)
  return (
    <>
      <Manage>
        <Header title='Logs' />
        <div className={styles.manageTable}>
          <div className={styles.manageTable__inner}>
            <table>
              <thead>
                <tr>
                  {logHeader.map((title) => (
                    <td className={styles.manageTable__td} key={title}>
                      {title}
                    </td>
                  ))}
                </tr>
              </thead>
              {/* </table> */}
              {/* <table> */}
              <tbody>
                {data.map((item) => (
                  <LogRow item={item} />
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.manageTable__pagination}>
            <Pagination
              incrementPage={incrementPage}
              decrementPage={decrementPage}
              page={page}
              lastPage={lastPage}
            />
          </div>
        </div>
      </Manage>
    </>
  )
}

export default Logs
