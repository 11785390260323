import React from 'react'
//components
import { BanDelete } from 'components'
//styles
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useToggleModal } from 'hooks'
import { fetchRequest } from 'utils'
import { toast } from 'react-toastify'
import { BanModal, DeleteModal } from 'modals'
import { useNavigate, useParams } from 'react-router'
import { BASE_URL } from 'config'
import { updateInstructor } from 'redux/actions/instructorData'
import { Person } from 'assets'

const Profile = () => {
  const { instructor } = useSelector((value) => value.instructorData)
  const deleteModal = useToggleModal()
  const banModal = useToggleModal()
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onRoute = () => {
    navigate('/manageInstructors')
  }

  const toggleBan = async (body, message) => {
    const response = await fetchRequest(
      `api/admin/ban/instructor`,
      'POST',
      body
    )

    if (response && response.success) {
      banModal.closeModal()
      dispatch(
        updateInstructor({
          creator: {
            ...instructor.creator,
            banned: !instructor.creator?.banned,
          },
        })
      )
      toast.info(message)
    }
  }

  const deleteInstructor = async () => {
    const response = await fetchRequest(
      `api/admin/delete/instructor?id=${params.id}`,
      'DELETE'
    )

    if (response && response.success) {
      deleteModal.closeModal()
      onRoute()
      toast.info('Instructor successfully removed!')
    }
  }

  const instructorBanToggle = async () => {
    const body = {
      id: instructor?.creator?._id,
    }

    if (!instructor?.creator?.banned) {
      toggleBan(body, 'Instructor successfully banned!')
    } else {
      toggleBan(body, 'Instructor successfully unbanned!')
    }
  }
  console.log('instructor', instructor)

  return (
    <>
      <div className={styles.profile}>
        <div className={styles.profile__row}>
          <div className={styles.profile__avatar}>
            {!instructor?.creator?.photo ? (
              <Person />
            ) : (
              <img
                style={{ width: '100%', height: '100%' }}
                src={instructor?.creator?.photo}
                alt={instructor?.creator?.photo}
              />
            )}
          </div>
          <div className={styles.profile__info}>
            <h2>{instructor.creator?.login}</h2>
            <h4>{instructor.creator?.phone}</h4>
          </div>
        </div>
        <p>Total posts: {instructor.totalCount ? instructor.totalCount : 0}</p>
        <div>
          <BanDelete
            onClickDelete={deleteModal.showModal}
            onClickBan={banModal.showModal}
            banTitle={instructor?.creator?.banned ? 'Unban' : 'Ban'}
          />
        </div>
      </div>
      {deleteModal.isShow && (
        <DeleteModal
          showModal={deleteModal.showModal}
          closeModal={deleteModal.closeModal}
          isOpen={deleteModal.isShow}
          title={'Delete instructor'}
          text={'Are you sure you want to delete instructor?'}
          onClick={deleteInstructor}
        />
      )}
      {banModal.isShow && (
        <BanModal
          showModal={banModal.showModal}
          closeModal={banModal.closeModal}
          isOpen={banModal.isShow}
          title={`${instructor?.creator?.banned ? 'Unban' : 'Ban'} instructor`}
          text={`Are you sure you want to ${
            instructor?.creator?.banned ? 'unban' : 'ban'
          } instructor?`}
          titleBtn={`${instructor?.creator?.banned ? 'Unban' : 'Ban'}`}
          onClick={instructorBanToggle}
        />
      )}
    </>
  )
}

export default Profile
