import React from "react";
//styles
import styles from "./styles.module.scss";

const Statistic = ({ label, value }) => {
  return (
    <div className={styles.statistic}>
      <div className={styles.statistic__label}>
        <div className={styles.statistic__color} />
        <h4>{label}</h4>
      </div>
      <h2>{value}</h2>
      <div className={styles.statistic__line} />
    </div>
  );
};

export default Statistic;
