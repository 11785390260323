import { combineReducers } from 'redux'
import { profileReducer } from './reducers/profileReducer'
import { adminsReducer } from './reducers/adminReducer'
import { instructorReducer } from './reducers/instructorReducer'

const rootReducer = combineReducers({
  profileData: profileReducer,
  adminsData: adminsReducer,
  instructorData: instructorReducer,
})

export default rootReducer
