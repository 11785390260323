import { SET_ADMINS, DELETE_ADMIN } from 'redux/actionTypes/adminData'

const initialState = {
  admins: [],
  superAdmin: null,
}

export const adminsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMINS:
      return { ...state, admins: [...action.payload] }
    case 'SET_SUPER_ADMIN':
      return { ...state, superAdmin: action.payload }
    case DELETE_ADMIN:
      return {
        ...state,
        admins: state.admins.filter(
          (element) => element._id !== action.payload.deletedAdmin._id
        ),
      }
    default:
      return state
  }
}
