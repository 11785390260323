import * as yup from "yup";
import validationValues from "../values";

const createAdmin = yup.object().shape({
  login: validationValues.login,
  password: validationValues.password,
  confirm: validationValues.passwordConfirmation,
});

export default createAdmin;
